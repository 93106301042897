import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Container = styled.section`
  ${({ theme }) => css`
    margin-bottom: 120px;
    ${theme.queries.medium} {
      margin-bottom: 159px;
    }
  `}
`

const Icon = styled.div`
  ${({ theme }) => css`
    border-radius: 50%;
    box-shadow: 0px 2px 4px #cacaca80;
    background: #f9f9f9;
    height: 63px;
    width: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.4s;
    cursor: pointer;

    &.active {
      background-color: #4b99c1;
    }

    &.active svg path {
      fill: #fff;
      transition: fill 0.4s;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    ${theme.queries.small} {
      &.active {
        background-color: #f9f9f9;
      }

      &.active svg path {
        fill: #000;
      }
    }
  `}
`

const Features = styled.ul`
  ${({ theme }) => css`
    li {
      align-items: center;
      display: flex;

      & + li {
        margin-top: 60px;
      }

      div {
        ${theme.queries.medium} {
          margin-left: 36px;
          max-width: 420px;
        }
      }
    }

    ${theme.queries.small} {
      li {
        flex-direction: column;

        div {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
        }
      }
    }
  `}
`

const Downloads = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    a {
      align-items: center;
      background-color: white;
      border: 2px solid ${theme.colors.celestialblue};
      border-radius: 43px;
      display: flex;
      justify-content: center;
      height: 67px;
      width: 208px;

      & + a {
        margin-top: 35px;
      }
    }

    ${theme.queries.medium} {
      align-items: baseline;
      flex-direction: row;
      margin-top: 60px;

      a {
        height: 78px;
        width: 242px;

        & + a {
          margin-left: 30px;
        }
      }
    }

    ${theme.queries.small} {
      flex-direction: row;
      
      a {
        width: 40%;
        padding: 10px;

        img {
          width: 100%;
        }

        & + a {
          margin-left: 20px;
          margin-top: 0;
        }

    }
  `}
`

const IconHolder = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
  `}
`

const Pointer = styled.div`
  ${({ theme }) => css`
    display: none;

    &.active {
      width: 100%;
      position: absolute;
      height: 2px;
      background-color: #4b99c1;
      left: -100%;
      display: block;
    }

    &.active::before {
      content: ' ';
      width: 8px;
      height: 8px;
      background-color: #4b99c1;
      border: none;
      border-radius: 100%;
      display: block;
      position: absolute;
      top: -3px;
    }

    ${theme.queries.small} {
      &.active {
        display: none;
      }
      &.active::before {
        display: none;
      }
    }
  `}
`

const Cellphone = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: block;

    .frame {
      z-index: 3;
      position: relative;
    }

    ${theme.queries.small} {
      display: none;
    }
  `}
`
const PhotoHolder = styled.div`
  ${({ theme }) => css`
    display: flex;
    position: absolute;
    width: 290px;
    height: 670px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -53%);
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 100%;
      transform: translate(0%, -3.7%);
      transition: transform 0.4s;
      z-index: 1;
    }

    img.active {
      transform: translate(-84%, -3.7%);
      z-index: 2;
    }
  `}
`

export {
  Container,
  Icon,
  Features,
  Downloads,
  IconHolder,
  Pointer,
  Cellphone,
  PhotoHolder,
}
