/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'

import Spacing from '../../../Spacing'
import Wrapper from '../../../Wrapper'
import Title from '../../../Title'

import { Container, Figure, List } from './styles'

const WhoToHelp = () => {
  const data = useStaticQuery(graphql`
    query {
      leaf: file(relativePath: { eq: "who-to-help.png" }) {
        childImageSharp {
          fixed(width: 681, height: 643) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Spacing
      top="159"
      bottom="163"
      bottomMobile="90"
      css={css`
        overflow-x: hidden;
      `}
    >
      <Wrapper>
        <Container>
          <div
            css={theme => css`
              ${theme.queries.medium} {
                margin: auto 0;
              }
            `}
          >
            <Title
              as="h2"
              mobileSize="28"
              mobileAlign="center"
              mobileHeight="35"
              marginBottom=""
              css={theme =>
                css`
                  margin: auto;
                  ${theme.queries.medium} {
                    max-width: 391px;
                  }
                `
              }
            >
              <FormattedMessage id="pages.home.whoToHelp.title" />
            </Title>
            <Wrapper>
              <List>
                <li>
                  <FormattedMessage id="pages.home.whoToHelp.ngo" />
                </li>
                <li>
                  <FormattedMessage id="pages.home.whoToHelp.orphanage" />
                </li>
                <li>
                  <FormattedMessage id="pages.home.whoToHelp.public_schools" />
                </li>
                <li>
                  <FormattedMessage id="pages.home.whoToHelp.natural_disasters_victims" />
                </li>
              </List>
            </Wrapper>
          </div>
          <Figure>
            <Img fixed={data.leaf.childImageSharp.fixed} />
          </Figure>
        </Container>
      </Wrapper>
    </Spacing>
  )
}

export default WhoToHelp
