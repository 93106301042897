import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Background from '../../../../images/hero-background.svg'

const Container = styled.section`
  ${({ theme }) => css`
    background-image: url(${Background});
    background-position: calc(5% - 190px) -1040px;
    padding: 168px 0 275px;
  `}
`

const Form = styled.form`
  ${({ theme }) => css`
    margin: 78px auto;
    max-width: 670px;

    ${theme.queries.medium} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    & > label {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      margin-bottom: 30px;

      &:focus {
        border: 1px solid black;
      }

      input,
      textarea {
        border: 0;
        border-bottom: 1px solid ${theme.colors.gray};
        height: 40px;
        width: 304px;
        background: transparent;
        outline: 0;
        padding: 0 5px;

        &:focus {
          border-bottom-color: ${theme.colors.celestialblue};
          border-bottom-width: 2px;
        }
      }

      textarea {
        padding-top: 12px;
        resize: none;
        font-family: 'MontSerrat', Arial, Helvetica, sans-serif !important;
      }

      ${theme.queries.small} {
        margin-top: 20px;
      }
    }
    button {
      height: 55px;
      width: 304px;
      grid-column-start: 2;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 305px;
      heigth: 55px;

      label {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        input {
          margin-right: 10px;
        }
      }
    }
  `}
`

export { Container, Form }
