/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Spacing from '../../../Spacing'
import Wrapper from '../../../Wrapper'
import Title from '../../../Title'
import Text from '../../../Text'

import { getSiteCurrentLanguage } from '../../../../utils/getLanguage'
import { LANGUAGE } from '../../../../utils/enum/language'

import {
  Container,
  Icon,
  Features,
  Downloads,
  IconHolder,
  Pointer,
  Cellphone,
  PhotoHolder,
} from './styles'

import QrCodeBlack from '../../../../images/icons/ic-qrcode-white.svg'
import AnonymousBlack from '../../../../images/icons/ic-anonimo-black.svg'
import LocationBlack from '../../../../images/icons/ic-localizacao-black.svg'
import Transparency from '../../../../images/icons/ic-transparencia.svg'
import Frame from '../../../../images/borda.png'
import CellphoneOne from '../../../../images/celular-01.png'
import CellphoneTwo from '../../../../images/celular-02.png'
import CellphoneThree from '../../../../images/celular-03.png'
import CellphoneFour from '../../../../images/celular-04.png'
import AppStoreEnglish from '../../../../images/ic-app-store-en.png'
import AppStorePortuguese from '../../../../images/ic-app-store-pt.png'
import GooglePlayEnglish from '../../../../images/ic-google-play-en.png'
import GooglePlayPortuguese from '../../../../images/ic-google-play-pt.png'

const Product = () => {
  const intl = useIntl()

  const currentLanguange = getSiteCurrentLanguage(intl)

  const [active, setActive] = useState(0)

  const setNewActive = index => {
    setActive(index)
  }

  const features = [
    {
      icon: <AnonymousBlack />,
      title: intl.formatMessage({ id: 'pages.home.product.feature_1_title' }),
      description: intl.formatMessage({
        id: 'pages.home.product.feature_1_description',
      }),
    },
    {
      icon: <LocationBlack />,
      title: intl.formatMessage({ id: 'pages.home.product.feature_2_title' }),
      description: intl.formatMessage({
        id: 'pages.home.product.feature_2_description',
      }),
    },
    {
      icon: <QrCodeBlack />,
      title: intl.formatMessage({ id: 'pages.home.product.feature_3_title' }),
      description: intl.formatMessage({
        id: 'pages.home.product.feature_3_description',
      }),
    },
    {
      icon: <Transparency />,
      title: intl.formatMessage({ id: 'pages.home.product.feature_4_title' }),
      description: intl.formatMessage({
        id: 'pages.home.product.feature_4_description',
      }),
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      product: file(relativePath: { eq: "img-produto.png" }) {
        childImageSharp {
          fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <Title
          align="center"
          mobileAling="center"
          mobileSize="28"
          marginBottom="40"
        >
          <FormattedMessage id="pages.home.product.title" />
        </Title>
        <Spacing
          bottomMobile="120"
          css={theme => css`
            ${theme.queries.medium} {
              align-items: center;
              display: flex;
              justify-content: space-around;
              margin-bottom: 100px;
            }
          `}
        >
          <Cellphone>
            <img src={Frame} className="frame" alt="" />
            <PhotoHolder>
              <img
                src={CellphoneOne}
                className={active === 0 ? 'active' : ''}
                alt=""
              />
              <img
                src={CellphoneTwo}
                className={active === 1 ? 'active' : ''}
                alt=""
              />
              <img
                src={CellphoneThree}
                className={active === 2 ? 'active' : ''}
                alt=""
              />
              <img
                src={CellphoneFour}
                className={active === 3 ? 'active' : ''}
                alt=""
              />
            </PhotoHolder>
          </Cellphone>
          <Features>
            {features.map((feature, index) => (
              <li key={feature.title}>
                <IconHolder>
                  <Pointer className={index === active ? 'active' : ''} />
                  <Icon
                    className={index === active ? 'active' : ''}
                    onClick={() => {
                      setNewActive(index)
                    }}
                  >
                    {feature.icon}
                  </Icon>
                </IconHolder>
                <div>
                  <Title as="h4">{feature.title}</Title>
                  <Text size="20" weight="300">
                    {feature.description}
                  </Text>
                </div>
              </li>
            ))}
          </Features>
        </Spacing>
        <Title as="h2" align="center" mobileSize="28" mobileHeight="35">
          <FormattedMessage id="pages.home.product.title_download" />
        </Title>
        <Text
          align="center"
          size="20"
          lineHeight="32"
          mobileSize="14"
          mobileHeight="22"
          css={theme =>
            css`
              margin: 30px auto;
              ${theme.queries.medium} {
                max-width: 555px;
              }
            `
          }
        >
          <FormattedMessage id="pages.home.product.text_download" />
        </Text>
        <Downloads>
          <a
            href="https://apps.apple.com/us/app/denario/id1438111232"
            target="blank"
          >
            <img
              src={
                currentLanguange === LANGUAGE.english
                  ? AppStoreEnglish
                  : AppStorePortuguese
              }
              alt=""
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=br.com.madeinweb.denario&hl=pt_BR"
            target="blank"
          >
            <img
              src={
                currentLanguange === LANGUAGE.english
                  ? GooglePlayEnglish
                  : GooglePlayPortuguese
              }
              alt=""
            />
          </a>
        </Downloads>
      </Wrapper>
    </Container>
  )
}

export default Product
