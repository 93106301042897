/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from '@emotion/styled'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import { toast } from 'react-toastify'
import { sendContactEmail } from '../../../../services/mail'

import Wrapper from '../../../Wrapper'
import Title from '../../../Title'
import Button from '../../../Button'

import { Container, Form } from './styles'

import Loading from '../../../../images/icons/loadingbutton.svg'

const StyledForm = styled(Form)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

const Contact = () => {
  const intl = useIntl()
  const [isLoading, setLoading] = useState(false)

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: { doador: 'on' },
  })

  const onSubmit = async ({ nome, doador, email, mensagem }) => {
    setLoading(true)

    try {
      await sendContactEmail({ nome, doador, email, mensagem })
      toast.success(intl.formatMessage({ id: 'toast_messages.success.email' }))

      reset({
        nome: '',
        email: '',
        mensagem: '',
      })
    } catch (error) {
      toast.success(intl.formatMessage({ id: 'toast_messages.error.email' }))
    }
    setLoading(false)
  }

  return (
    <Container>
      <Wrapper>
        <Title
          as="h2"
          align="center"
          css={theme =>
            css`
              ${theme.queries.small} {
                font-size: 28px;
              }
            `
          }
        >
          <FormattedMessage id="pages.home.contact.title" />
        </Title>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="nome">
            <FormattedMessage id="pages.home.contact.form.name" />
            <input type="text" name="nome" ref={register({ required: true })} />
          </label>
          <div>
            <label htmlFor="doador">
              <FormattedMessage id="pages.home.contact.form.donator" />
              <input type="radio" name="doador" value="on" ref={register()} />
            </label>
            <label htmlFor="doador">
              <FormattedMessage id="pages.home.contact.form.institution" />
              <input type="radio" name="doador" value="off" ref={register()} />
            </label>
          </div>
          <label htmlFor="name">
            <FormattedMessage id="pages.home.contact.form.email" />
            <input
              type="email"
              name="email"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="mensagem">
            <FormattedMessage id="pages.home.contact.form.message" />
            <textarea
              name="mensagem"
              cols="40"
              ref={register({ required: true })}
            />
          </label>
          <Button whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
            {isLoading ? (
              <Loading />
            ) : (
              intl.formatMessage({ id: 'pages.home.contact.form.send_message' })
            )}
          </Button>
        </StyledForm>
      </Wrapper>
    </Container>
  )
}

export default Contact
