import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Background from '../../../../images/hero-background.svg'

const Container = styled.header`
  ${({ theme }) => css`
    background-image: url(${Background});
    background-position: calc(50% - 190px) -1040px;
    margin-top: -320px;
    margin-bottom: 70px;
    padding-top: 340px;

    ${theme.queries.medium} {
      padding: 255px 0 255px;
      margin-top: -160px;
    }
  `}
`

export default Container
