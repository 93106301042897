/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Img from 'gatsby-image'

import Wrapper from '../../../Wrapper'
import FlexContainer from '../../../FlexContainer'
import Title from '../../../Title'
import Text from '../../../Text'
import Button from '../../../Button'

import Container from './styles'

import { store } from '../../../../store'

import { getSiteCurrentLanguage } from '../../../../utils/getLanguage'
import { LANGUAGE } from '../../../../utils/enum/language'

const Hero = () => {
  const { logged } = store.getState().auth
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      figure_en: file(relativePath: { eq: "hero-home-figure-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      figure_pt: file(relativePath: { eq: "hero-home-figure-pt.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <FlexContainer>
          <div
            css={theme => css`
              ${theme.queries.small} {
                max-width: 80%;
                margin: auto;
              }
              margin-right: -100px;
            `}
          >
            <Text
              size="20"
              color="celestialblue"
              weight="500"
              marginBottom="40"
              marginBottomMobile="14"
              mobileAlign="center"
            >
              <FormattedMessage id="pages.home.header.pre_title" />
            </Text>
            <Title
              marginBottom="29"
              mobileAlign="center"
              mobileSize="28"
              mobileHeight="35"
            >
              <FormattedMessage id="pages.home.header.title" />
            </Title>
            <Text
              size="20"
              weight="300"
              marginBottom="37"
              mobileAlign="center"
              mobileSize="14"
              mobileWeight="400"
            >
              <FormattedMessage id="pages.home.header.subtitle" />
            </Text>
            <Link
              to={logged ? '/instituicoes' : '/login'}
              css={theme => css`
                ${theme.queries.small} {
                  display: table;
                  margin: 33px auto;
                }
              `}
            >
              <Button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                uppercase
              >
                <FormattedMessage id="donate_now" />
              </Button>
            </Link>
          </div>
          {getSiteCurrentLanguage(intl) === LANGUAGE.english && (
            <Img
              loading="eager"
              fluid={data.figure_en.childImageSharp.fluid}
              css={theme => css`
                ${theme.queries.large} {
                  height: fit-content;
                  transform: translateX(110px);
                  width: 1240px;
                }
              `}
            />
          )}
          {getSiteCurrentLanguage(intl) === LANGUAGE.portuguese && (
            <Img
              loading="eager"
              fluid={data.figure_pt.childImageSharp.fluid}
              css={theme => css`
                ${theme.queries.large} {
                  height: fit-content;
                  transform: translateX(110px);
                  width: 1240px;
                }
              `}
            />
          )}
        </FlexContainer>
      </Wrapper>
    </Container>
  )
}

export default Hero
