/** @jsx jsx */
import { jsx } from '@emotion/core'

import Layout from '../components/Layout'

import BetterWorld from '../components/pages/Home/BetterWorld'
import Contact from '../components/pages/Home/Contact'
import Header from '../components/pages/Home/Header'
import Product from '../components/pages/Home/Product'
import WhoToHelp from '../components/pages/Home/WhoToHelp'

const IndexPage = () => (
  <Layout>
    <Header />
    <Product />
    <WhoToHelp />
    <BetterWorld />
    <Contact />
  </Layout>
)

export default IndexPage
