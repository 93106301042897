import { css } from '@emotion/core'
import styled from '@emotion/styled'

import IconTopic from '../../../../images/ic-topico.svg'

const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `}
`

const List = styled.ul`
  ${({ theme }) => css`
    font-size: 24px;
    line-height: 40px;
    margin-top: 20px;

    li {
      font-size: 14px;
      margin-left: 28px;
      position: relative;

      &:before {
        content: '';
        background-image: url(${IconTopic});
        background-position: center center;
        background-repeat: no-repeat;
        height: 20px;
        position: absolute;
        width: 20px;
        transform: translate(-28px, 50%);
      }
    }

    ${theme.queries.medium} {
      line-height: 60px;
      margin-top: 46px;
      li {
        font-size: 24px;
        margin-left: 38px;

        &:before {
          transform: translate(-38px, 100%);
        }
      }
    }
  `}
`

const Figure = styled.figure`
  ${({ theme }) => css`
    ${theme.queries.small} {
      display: none !important;
    }

    position: relative;
    heigth: 616px;
    width: 432px;
  `}
`

export { Container, List, Figure }
