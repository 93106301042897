/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

import Title from '../../../Title'
import Text from '../../../Text'
import { LANGUAGE } from '../../../../utils/enum/language'
import { getSiteCurrentLanguage } from '../../../../utils/getLanguage'

import objetivosImageEnglish from '../../../../images/logo-objetivos-sustentavel-en.png'
import objetivosImagePortuguese from '../../../../images/logo-objetivos-sustentavel-pt.png'

import { Container, Content, Shape, TextContainer } from './styles'

const BetterWorld = () => {
  const intl = useIntl()

  const isEnglish = getSiteCurrentLanguage(intl) === LANGUAGE.english

  return (
    <Container>
      <Content>
        <Title
          color="white"
          as="h2"
          size="64"
          mobileSize="28"
          mobileAlign="center"
          mobileHeight="35"
          marginBottom="30"
        >
          <FormattedMessage id="pages.home.betterWorld.title" />
        </Title>
        <TextContainer>
          <Text
            color="white"
            size="20"
            weight="300"
            mobileSize="14"
            mobileAlign="center"
            mobileHeight="22"
            marginBottomMobile="20"
          >
            <FormattedMessage id="pages.home.betterWorld.text1" />
          </Text>
          <Text
            color="white"
            size="20"
            weight="300"
            mobileSize="14"
            mobileAlign="center"
            mobileHeight="22"
            marginBottomMobile="39"
          >
            <FormattedMessage id="pages.home.betterWorld.text2" />
          </Text>
        </TextContainer>
      </Content>
      <Shape
        image={isEnglish ? objetivosImageEnglish : objetivosImagePortuguese}
      />
    </Container>
  )
}

export default BetterWorld
