import styled from '@emotion/styled'
import { css } from '@emotion/core'

import background from '../../../../images/img-fundo-ods-desk.png'

export const Container = styled.section`
  ${({ theme }) => css`
    background-image: url(${background});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 91px 18% 91px 10%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    ${theme.queries.medium} {
      flex-direction: row;
      height: 540px;
      padding: 91px 5%;
      width: 100%;
    }
  `}
`

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1px solid white;

    ${theme.queries.medium} {
      width: 723px;
    }
  `}
`

export const Shape = styled.div`
  ${({ theme, image }) => css`
    align-self: center;
    background-color: white;
    background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110px;
    border-radius: 50%;
    height: 140px;
    width: 140px;

    ${theme.queries.medium} {
      background-size: 140px;
      height: 196px;
      width: 196px;
    }
  `}
`

export const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;

    ${theme.queries.small} {
      height: unset;
    }
  `}
`
